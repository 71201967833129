import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Seo from '../components/seo'
import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulReferencePost.nodes')
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')

    if (author.heroImage) {
      return (
        <Layout location={this.props.location}>
          <Seo
            description={author.shortBio.shortBio}
            image={author.heroImage.resize.src}
          />
          <Hero
            image={author.heroImage.gatsbyImageData}
            title={author.name}
            content={author.shortBio.shortBio}
          />
          <ArticlePreview posts={posts.slice(0, 3)} />
        </Layout>
      )
    } else {
      return (
        <Layout location={this.props.location}>
          <Seo
            description={author.shortBio.shortBio}
          />
          <Hero
            title={author.name}
            content={author.shortBio.shortBio}
          />
          <ArticlePreview posts={posts.slice(0, 3)} />
        </Layout>
      )
    }

  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulReferencePost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY MMMM Do")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        name
        shortBio {
          shortBio
        }
        title
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
          resize(height: 630, width: 1200) {
            src
          }
        }
      }
    }
  }
`
